.mainContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 64px;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
}

.header {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

.align-center {
  display: flex;
  justify-content: center;
}

.lh15 {
  line-height: 1.5em;
}

.heart-logo {
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.sm-pl {
  padding-left: 0.2em;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.p-5 {
  padding: 1.25rem;
}

.underline {
  text-decoration: underline;
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: #35aee2;
}

md:p-16 {
  padding: 4rem;
}

.bio-text {
  background-color: white;
  color: black;
  width: fit-content;
  padding: 0.2em;
}

.bio {
  text-align: center;
  color: white;
  margin-top: 1em;
  margin-bottom: 1em;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.waveButton {
  cursor: pointer;
  /* margin-top: 16px; */
  padding: 1em;
  border: 0;
  border-radius: 5px;
  margin: 1em;
}

.rainbow {
  background-color: black;
  background-image: linear-gradient(
    to right,
    var(--light-purple),
    var(--purple),
    var(--blue),
    var(--green),
    var(--yellow),
    var(--orange),
    var(--red)
  );
}

.App {
  height: 100vh;
  background-color: #0d1116;
  overflow: scroll;
  text-align: center;
  font-family: "Azeret Mono", monospace;
}

.container {
  height: 100%;
  background-color: #0d1116;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.header-container {
  padding-top: 30px;
}

/* .header {
  margin: 0;
  font-size: 50px;
  font-weight: bold;
} */

.sub-text {
  font-size: 16px;
  color: white;
  /* color: linear-gradient() */
}

.gradient-text {
  background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.loot-gradient-text {
  background: -webkit-linear-gradient(left, #999999, #BBBBBB);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient-animation 4s ease infinite;
}

.no-decoration {
  text-decoration: none;
  color: #35aee2;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  /* padding-left: 40px;
  padding-right: 40px; */
  border-radius: 5px;
  cursor: pointer;
  /* font-size: 16px; */
  font-weight: bold;
  color: white;
}

.connect-wallet-button {
  background: -webkit-linear-gradient(left, #60c657, #35aee2);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.mining-logo, confirmed-logo, loading-logo {
  max-height: 200px;
  margin-top: 1em;
}

.opensea-button {
  background-color: #475c64 !important;
  margin: 1em;
  padding: 1em;
}

.mint-button {
  background: -webkit-linear-gradient(left, #a200d6, #ff6fdf);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin-right: 15px;
}

.opensea-button {
  background-color: rgb(32, 129, 226);
}

.mint-count {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.footer-wrapper {
  display: flex;
  margin-top: auto;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 1em;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.twitter-logo {
  width: 30px;
  height: 30px;
}

.contract-logo {
  width: 36px;
  align-items: center;
  justify-content: center;
  height: 36px;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 0.3em;
}

.white-text {
  color: white;
  padding: 0;
  margin: 0.1em;
}

.footer-text {
  color: #35aee2;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.bg-clip-text {
  background-clip: text;
}

.text-transparent {
  -webkit-text-fill-color: transparent;
}

.font-bold {
  font-weight: bold;
}

:root {
  --light-purple: #ac62ed;
  --purple: #4e00e0;
  --blue: #38aef4;
  --green: #4bde79;
  --yellow: #ffed26;
  --orange: #ffae5f;
  --red: #ff5353;
}